body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.logo-login {
  width: 100%
}

.logo-login2 {
  width: 85%;
  max-width: 300px;
}

.fs-24-rem {
  font-size: 3.5rem;
}

.btn-primary {
  background-color: #ff9100 !important;
}

.btn-primary:hover {
  background-color: #ff9002 !important;
}

.coluna-conteudo-card {
  width: 300px;
}

.coluna-conteudo-card-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.col-tags {
  min-width: 200px !important
}

.badge-tag {
  margin-right: 5px;
}

.font-primary {
  color: #ff9100 !important;
}

.badge-tag i {
  font-size: 10px;
  margin-right: 5px;
}

.nav-item {
  cursor: pointer;
}

.m-auto {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.btn-tabs .btn {
  font-size: 15px !important;
}

.horizontal-scroll {
  overflow: auto;
  white-space: nowrap;
}

.horizontal-scroll a {
  display: inline-block;
}

.btn:hover {
  opacity: 0.9;
}

.text-1 {
  font-size: 14px !important;
  font-weight: bold;
}

.text-2 {
  font-size: 13px;
  font-weight: bold;
}

.text-3 {
  font-size: 15px;
  font-weight: bold;
}


.table-children1 {
  background-color: #f9f9f9;
}

.table-children-1-pd {
  padding-left: 30px
}

.table-children2 {
  background-color: #fff;
}

.table-children-2-pd {
  padding-left: 70px
}

.icon-table-acord {
  font-size: 16px;
  padding-right: 10px;
  color:#777
}

.no-arrow {
color: transparent !important
}


.d-contents {
  display: contents !important;
}

.flex-auto {
  flex: auto !important
}

.accordion-button::after {
  position: absolute;
  background-size: 25px !important;
  width: 25px !important;
  height: 25px !important
}

.col-taxonomia {
  width: 500px;
  max-width: 500px;
}

.col-at {
  width: 100px;
  max-width: 100px;
}

.col-cards-questoes {
  width: 200px;
  max-width: 200px;
}

.col-status {
  width: 100px;
  max-width: 100px;
}

.col-acoes {
  width: 200px;
  max-width: 200px;
}

.espaco-taxonomia-1 {
  padding-left: 40px
}

.accordion-body {
  padding: 0 !important;
}

.accordion-item {
  padding: 0 !important;
}

.accordion-button {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.espaco-header-col {
  padding-left: 20px
}

.espaco-tema-col {
  margin-left: 20px
}

.dropdown-toggle::after {
  display: none !important;
}

.table td {
  vertical-align: middle;
}

.max-width-id {
  width: 80px;
}

.thead-inputs th {
  padding: 8px !important; 
}

.thead-inputs label {
  float: left;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 5px;
  padding-bottom: 2px;
}

.thead-inputs th:last-child {
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 5px;
  padding-bottom: 2px;
}

.dropdown-toggle i {
  padding: 0 !important
}

.btn-drop button {
  padding: 0 !important
}

.aside-enabled.aside-fixed.header-fixed .header {
  border-bottom: 1px solid #ededed;
}

h1 {
  line-height: 30px !important;
}

.nav-stretch {
padding-bottom: 10px !important;
font-size: 18px;
font-weight: bold;
text-transform: uppercase;
}

.font-bold {
  font-weight: bold;
}


.form-control.form-control-solid {
  background-color: #e8eef1 !important;
}

.alternativa-correta textarea {
border-color: #50cd89 !important
}

.alternativa-correta {
  color: #50cd89;
}

.min-width-alternativa {
  min-width: 20px;
  font-weight: bold;
}

.dropdown-divider {
  border-top: none !important;
  background-color: #a9a9b3;
}

.min-width-c-q {
  min-width: 150px;
}

.badge-light-pink {
  color: #ea39e2 !important;
  background-color: #f8f5ff;
}

.text-pink {
  color: #ea39e2 !important;
}